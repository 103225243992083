<template>
    <div class="f1 apps-page w-full dF fC f1 px-4">
        <div class="dF aC">
            <div class="dF aC f1">
                <h3>Partners</h3>
            </div>
        </div>
        <a-card :bodyStyle="{ padding: 0 }" class="my-3">
            <div>
                <a-table
                    :rowKey="(e) => e.key"
                    :columns="columns"
                    :dataSource="partners"
                    :loading="loading"
                    :pagination="{
                        current: currentPage,
                        total: totalPartners,
                        pageSize: pageSize,
                    }"
                    @change="handleChange"
                >
                    <div slot="readableId" slot-scope="partner">
                        {{ partner.readableId }}
                    </div>
                    <div slot="companyName" slot-scope="partner">
                        {{ partner.companyName }}
                    </div>
                    <div slot="contactPerson" slot-scope="partner">
                        <span
                            v-if="
                                partner.contactFirstName &&
                                partner.contactLastName
                            "
                            >{{
                                partner.contactFirstName +
                                " " +
                                partner.contactLastName
                            }}</span
                        >
                    </div>
                    <div slot="email" slot-scope="partner">
                        {{ partner.email }}
                    </div>
                    <div slot="contactRole" slot-scope="partner">
                        {{ partner.contactRole }}
                    </div>
                    <div slot="phone" slot-scope="partner">
                        {{ partner.phone }}
                    </div>
                    <div slot="industry" slot-scope="partner">
                        {{ partner.industry }}
                    </div>
                    <div
                        slot="administrators"
                        slot-scope="partner"
                        class="dF aC"
                        style="justify-content: center"
                    >
                        <a-avatar
                            style="margin-left: -10px"
                            shape="circle"
                            size="default"
                            :class="$style.avatar"
                            v-for="user in first3Users(partner)"
                            :key="user.id"
                            :style="{
                                'background-color':
                                    user.id === 'id'
                                        ? '#9693e7'
                                        : 'var(--primary)',
                            }"
                            >{{
                                user.firstName
                                    ? user.id !== "id"
                                        ? user.firstName[0].toUpperCase()
                                        : user.firstName
                                    : "--"
                            }}</a-avatar
                        >
                    </div>
                    <div
                        slot="createdAt"
                        slot-scope="partner"
                        style="color: var(--med-gray)"
                    >
                        {{ getDate(partner.createdAt) }}
                    </div>
                    <div
                        slot="updatedAt"
                        slot-scope="partner"
                        style="color: var(--med-gray)"
                    >
                        {{ getDate(partner.updatedAt) }}
                    </div>
                    <div
                        slot="actions"
                        slot-scope="partner"
                        class="dF aC"
                        style="gap: 20px"
                    >
                        <div
                            @click="editPartner(partner)"
                            class="popoverContent"
                        >
							<a-icon type="edit" />
                        </div>
                        <div
                            @click="selectedPartner = partner;deleteModal = true;"
                            class="popoverContent"
                        >
							<a-icon type="delete" style="color:red" />
                        </div>
                    </div>
                </a-table>
            </div>
        </a-card>
        <a-modal
            v-model="deleteModal"
            title="Delete Partners"
            ok-text="Delete"
            @ok="deletePartner"
            :centered="true"
        >
            <p>
                Are you sure you want to delete this partner
                <strong>
                    {{ selectedPartner.companyName }}
                </strong>
                ?
            </p>
        </a-modal>
        <a-modal
            v-model="createNewPartners"
            title="Add New Partner"
            ok-text="CREATE"
			@cancel="onModalClose"
            @ok="createPartners"
            :confirmLoading="confirmLoading"
            :width="900"
        >
            <a-form-model ref="partner" :model="partner" :rules="rules">
                <a-row :gutter="16">
					<a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item prop="owner" label="Owner" required>
                            <a-select
                                v-model="partner.owner"
                                show-search
                                :filter-option="filterOption"
                                option-filter-prop="email"
                                size="large"
                                placeholder="Select Owner"
                                style="width: 100%"
								@change="onOwnerChange"
                            >
                                <a-select-option
                                    v-for="(user, i) in allUsers"
                                    :key="(i + 9).toString(36) + i"
                                    :value="user.id"
                                >
                                    {{ user.email }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="companyName"
                            label="Legal Company name"
                            required
                        >
                            <a-input
                                v-model="partner.companyName"
                                placeholder="Legal Company name"
                                size="large"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="industry"
                            label="Business Type"
                            required
                        >
                            <a-select
                                placeholder="Select Business Type"
                                size="large"
                                v-model="partner.industry"
                            >
                                <a-select-option value="Builder">
                                    Builder
                                </a-select-option>
                                <a-select-option value="Real Estate Broker">
                                    Real Estate Broker
                                </a-select-option>
                                <a-select-option value="Advertising Agency">
                                    Advertising Agency
                                </a-select-option>
                                <a-select-option value="Freelancer">
                                    Freelancer
                                </a-select-option>
                                <a-select-option value="Other">
                                    Other
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="24">
                        <a-form-model-item prop="users" label="Users">
                            <a-select
                                mode="multiple"
                                show-search
                                placeholder="Choose a user(s)"
                                v-model="partner.users"
                                :filter-option="filterOption"
                                option-filter-prop="email"
                                size="large"
                                style="width: 100%"
                            >
                                <a-select-option
                                    v-for="(user, i) in allUsers"
                                    :key="(i + 9).toString(36) + i"
                                    :value="user.id"
                                >
                                    {{ user.email }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 16">
                        <a-form-model-item
                            prop="address"
                            label="Address"
                            required
                        >
                            <a-input
                                placeholder="Address"
                                size="large"
                                v-model="partner.address"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="country"
                            label="Country"
                            required
                        >
                            <a-select
                                show-search
                                size="large"
                                class="dropdown-style"
                                v-model="partner.country"
                                @change="
                                    partner.region = null;
                                    partner.postal = null;
                                "
                            >
                                <a-select-option
                                    v-for="country in countries"
                                    :key="country.value"
                                    :value="country.name"
                                >
                                    {{ country.name }}
                                </a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item prop="city" label="City" required>
                            <a-input
                                placeholder="City"
                                size="large"
                                v-model="partner.city"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="region"
                            label="State/Province"
                            required
                        >
                            <a-input
                                placeholder="State/Province"
                                size="large"
                                v-model="partner.region"
                            />
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="postal"
                            label="Zip/Postal Code"
                            required
                        >
                            <a-input
                                placeholder="Zip/Postal Code"
                                size="large"
                                v-model="partner.postal"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            ref="contactFirstName"
                            label="First Name"
                            prop="contactFirstName"
                        >
                            <a-input
                                v-model="partner.contactFirstName"
                                placeholder="First Name"
                                size="large"
                                required
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            ref="contactLastName"
                            label="Last Name"
                            prop="contactLastName"
                        >
                            <a-input
                                v-model="partner.contactLastName"
                                placeholder="Last Name"
                                size="large"
                                required
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 8">
                        <a-form-model-item
                            prop="contactRole"
                            label="Your Role"
                            required
                        >
                            <a-input
                                placeholder="Your Role"
                                size="large"
                                v-model="partner.contactRole"
                            />
                        </a-form-model-item>
                    </a-col>
                </a-row>
                <a-row :gutter="16">
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            ref="email"
                            label="Email Address"
                            prop="email"
                        >
                            <a-input
                                v-model="partner.email"
                                placeholder="Email Address"
                                size="large"
                                required
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :span="$mq == 'sm' ? 24 : 12">
                        <a-form-model-item
                            prop="phone"
                            label="Phone Number"
                            required
                        >
                            <a-input
                                v-model="partner.phone"
                                placeholder="Phone Number"
                                size="large"
                                type="number"
                            >
                            </a-input>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import { mapMutations } from "vuex";
import moment from "moment";
import postalCodes from "postal-codes-js";
import countryData from "@/staticData/countryData";
import _ from "lodash";

export default {
    data() {
        return {
            sortedInfo: {
                key: "createdAt",
                order: "DESC",
            },
            partners: [],
            recipients: [],
            loading: false,
            deleteModal: false,
            confirmLoading: false,
            currentPage: 1,
            totalPartners: 0,
            pageSize: 10,
            selectedPartner: {},
            countries: countryData.countryList,
            partner: {
                companyName: "",
                owner: "",
                industry: "",
                address: "",
                country: "",
                region: "",
                city: "",
                postal: "",
                contactFirstName: "",
                contactLastName: "",
                email: "",
                phone: "",
                contactRole: "",
                users: [],
            },
        };
    },
    computed: {
        createNewPartners: {
            get() {
                return this.$store.state.addNew["partners"];
            },
            set() {
                this.UPDATE_ADD_NEW({
                    key: "partners",
                    value: false,
                });
            },
        },

        allUsers() {
            return this.$store.state.allUsers;
        },

        search() {
            return this.$store.state.searchFilter.toLowerCase();
        },

        columns() {
            return [
                {
                    title: "Company Name",
                    key: "companyName",
                    scopedSlots: { customRender: "companyName" },
                    sorter: true,
                },
                {
                    title: "Main Contact",
                    key: "contactPerson",
                    scopedSlots: { customRender: "contactPerson" },
                    sorter: true,
                },
                {
                    title: "Email",
                    key: "email",
                    scopedSlots: { customRender: "email" },
                    sorter: true,
                },
                {
                    title: "Phone Number",
                    key: "phone",
                    scopedSlots: { customRender: "phone" },
                    sorter: true,
                },
                {
                    title: "Role",
                    key: "contactRole",
                    scopedSlots: { customRender: "contactRole" },
                    sorter: true,
                },
                {
                    title: "Business Type",
                    key: "industry",
                    scopedSlots: { customRender: "industry" },
                    sorter: true,
                },
                {
                    title: "Company Administrators",
                    key: "administrators",
                    scopedSlots: { customRender: "administrators" },
                    sorter: true,
                },
                {
                    title: "Account Created",
                    key: "createdAt",
                    scopedSlots: { customRender: "createdAt" },
                    sorter: true,
                },
                {
                    title: "Account Updated",
                    key: "updatedAt",
                    scopedSlots: { customRender: "updatedAt" },
                    sorter: true,
                },
                {
                    title: "",
                    key: "actions",
                    scopedSlots: { customRender: "actions" },
                },
            ];
        },

        rules() {
            return {
                email: [
                    { validator: this.validateEmail, trigger: "change" },
                    {
                        required: true,
                        message: "Please enter your email",
                        trigger: "change",
                    },
                ],
                postal: [
                    { validator: this.validatePostalCodes, trigger: "change" },
                    {
                        required: true,
                        message: "Please enter your Postal code",
                        trigger: "change",
                    },
                ],
                contactFirstName: [
                    {
                        required: true,
                        message: "Please enter your first name",
                        trigger: "change",
                    },
                ],
                contactLastName: [
                    {
                        required: true,
                        message: "Please enter your last name",
                        trigger: "change",
                    },
                ],
                contactRole: [
                    {
                        required: true,
                        message: "Please enter your role",
                        trigger: "change",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "Please enter your business number",
                        trigger: "change",
                    },
                ],
                companyName: [
                    {
                        required: true,
                        message: "Please enter your companyName",
                        trigger: "change",
                    },
                ],
                owner: [
                    {
                        required: true,
                        message: "Please select the owner",
                        trigger: "change",
                    },
                ],
                industry: [
                    {
                        required: true,
                        message: "Please select your business type",
                        trigger: "change",
                    },
                ],
                address: [
                    {
                        required: true,
                        message: "Please enter your address",
                        trigger: "change",
                    },
                ],
                country: [
                    {
                        required: true,
                        message: "Please select your country",
                        trigger: "change",
                    },
                ],
                region: [
                    {
                        required: true,
                        message: "Please enter your region",
                        trigger: "change",
                    },
                ],
                city: [
                    {
                        required: true,
                        message: "Please enter your city",
                        trigger: "change",
                    },
                ],
            };
        },
    },
    watch: {
        search() {
            this.searchDebounce();
        },
    },
    created() {
        this.getPartnersCount();
        this.getPartners();
        // this.$store.dispatch('FETCH_ALL_USERS')
    },

    methods: {
        ...mapMutations([
            "SET_PARTNER",
            "UPDATE_SEARCH",
            "UPDATE_ADD_NEW",
            "SET_USERS",
        ]),

        searchDebounce: _.debounce(function () {
            this.getPartnersCount();
            this.getPartners();
        }, 1000),

        async getPartnersCount() {
            try {
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `?companyName=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `/partners/count${searchQuery}`
                );
                this.totalPartners = data;
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching partner count. Please try again."))
				}
            }
        },

        async getPartners() {
            this.loading = true;
            try {
                let start = (this.currentPage - 1) * this.pageSize;
                let searchQuery = "";
                if (this.search) {
                    searchQuery = `&code=${this.search}`;
                }
                let { data } = await this.$api.get(
                    `/partners?_start=${start}&_limit=${this.pageSize}&_sort=${this.sortedInfo.key}:${this.sortedInfo.order}${searchQuery}`
                );
                this.partners = data.map((u, index) => {
                    return {
                        ...u,
                        key: index,
                    };
                });
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while fetching partner list. Please try again."))
				}
            }
            this.loading = false;
        },

        createPartners() {
            this.$refs.partner.validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.$api
                        .post(`/partners`, {
                            ...this.partner,
                        })
                        .then(() => {
                            this.confirmLoading = false;

                            this.$message.success(
                                "Successfully created new partner"
                            );
                            this.UPDATE_ADD_NEW({
                                key: "partners",
                                value: false,
                            });
                        })
                        .catch((err) => {
                            this.confirmLoading = false;
							if (err?.response?.status !== 400) {
								this.$message.error(this.$err(err, "Error while creating new partner. Please try again!"));
							}
                        });
                } else {
                    console.error("Invalid form details");
                    return false;
                }
            });
        },

        async deletePartner() {
            try {
                await this.$api.delete(`/partners/${this.selectedPartner.id}`);
                this.deleteModal = false;
                this.selectedPartner = {};
                this.getPartnersCount();
                this.getPartners();
            } catch (err) {
				if (err?.response?.status !== 400) {
					this.$message.error(this.$err(err, "Error while deleting partner. Please try again!"))
				}
            }
        },

        validateEmail(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter an email"));
            } else {
                const result =
                    /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(
                        value
                    );
                if (result) {
                    callback();
                } else {
                    callback(new Error("That's not a valid email address."));
                }
            }
        },

        validatePostalCodes(rule, value, callback) {
            if (value === "") {
                callback(new Error("Please enter a Zip/Postal Code"));
            } else {
                if (!this.partner.country) {
                    callback(new Error("Please select a country first"));
                }
                const countryCode = countryData.countries[this.partner.country];
                const result = postalCodes.validate(countryCode, value);
                if (result === true) {
                    callback();
                } else {
                    callback(new Error(result));
                }
            }
        },

        getDate(date) {
            return moment(date).format("D/M/YYYY");
        },

        editPartner(partner) {
            this.SET_PARTNER(partner);
            this.$router.push(`/partners/${partner.id}`);
        },

        handleChange(pagination, filter, sorter) {
            if (pagination) {
                this.currentPage = pagination.current;
            }
            if (sorter && sorter.column) {
                this.sortedInfo = {
                    key: sorter.columnKey,
                    order: sorter.order === "descend" ? "DESC" : "ASC",
                };
				this.getPartners();
            }
        },

        req: (msg) => ({ required: true, message: msg }),

        formatterValue(value) {
            if (this.partner.type === "percentage") {
                return value + " %";
            } else if (this.partner.type === "amount") {
                return "$ " + value;
            }
            return value;
        },

        disabledDate(current) {
            if (!this.partner.startDate) {
                return;
            }
            // Can not select days before start date
            return (
                current && current < moment(this.partner.startDate).endOf("day")
            );
        },

        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
            );
        },

        first3Users(partner = { users: [] }) {
            let members = partner.users.filter((u) => u.confirmed);
            const extraMembers = members.length > 3 ? members.length - 3 : 0;
            members = members.filter((u, i) => i < 3);
            if (extraMembers) {
                members.push({ firstName: `+${extraMembers}`, id: "id" });
            }
            return members;
        },

		onOwnerChange(id) {
			this.confirmLoading = true
			const user = this.allUsers.find(u => u.id === id)

			if(user){
				this.partner = {
					...this.partner,
					companyName: user.company,
					industry: user.industry,
					address: user.address,
					country: user.country,
					region: user.region,
					city: user.city,
					postal: user.postal,
					contactFirstName: user.firstName,
					contactLastName: user.lastName,
					email: user.email,
					phone: user.phone,
					contactRole: user.userRole,
				};
			}
			this.confirmLoading = false;
		},

		onModalClose() {
			this.$refs.partner && this.$refs.partner.clearValidate();
			this.partner = {
				companyName: "",
				owner: "",
				industry: "",
				address: "",
				country: "",
				region: "",
				city: "",
				postal: "",
				contactFirstName: "",
				contactLastName: "",
				email: "",
				phone: "",
				contactRole: "",
				users: [],
			}
		}
    },

    beforeDestroy() {
        this.UPDATE_SEARCH("");
    },
};
</script>

<style lang="scss" module>
@import "./style.module.scss";
</style>

<style lang="scss" scoped>
.button {
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    padding: 4px 10px;
}

.popoverContent {
    height: max-content;
    line-height: 30px;
    padding: 0 10px;
}
.popoverContent:hover {
    background-color: var(--off-white-light);
    color: var(--primary);
    cursor: pointer;
}
</style>

<style lang="scss">
.popoverStyle .ant-popover-inner-content {
    padding: 5px;
    background-color: white;
    border-radius: 20px;
}
</style>
